import React from "react"
import { useState } from "react"

export default function GoogleIcon ({ className }) {
  const [isHovering, setIsHovering] = useState("#8892B0")

  return (
    <a
      className={className}
      href="mailto:dina@heymynameisdina.com, dinasmither@gmail.com"
      target="_blank"
      onMouseEnter={() => setIsHovering("#CCD6F6")}
      onMouseLeave={() => setIsHovering("#8892B0")}
      alt="Google Mail Link"
      rel="noreferrer"
      aria-label="Google Mail Link"
    >
      <svg
        width="30px"
        height="30px"
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
      >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="95039-free-social-media-icons_social-media-icons-all" transform="translate(-1068.000000, -648.000000)" fill={isHovering} fillRule="nonzero">
                <path d="M1068.71557,672.369185 C1068.24006,660.005947 1079.06582,648.593728 1091.44491,648.435225 C1097.73692,648.06575 1103.9212,650.189817 1108.65834,654.347388 C1106.6929,656.487179 1104.71161,658.626971 1102.58767,660.68751 C1098.37149,658.135611 1093.31525,656.201874 1088.4175,657.929557 C1083.32101,659.750137 1079.58292,664.151368 1078.61135,669.47535 C1077.63977,674.799331 1079.5823,680.237223 1083.70722,683.740625 C1087.83213,687.244026 1093.51276,688.280686 1098.60925,686.460105 C1102.49988,684.842257 1105.38915,681.470465 1106.39175,677.377881 C1101.81101,677.298629 1097.23027,677.377881 1092.64953,677.219378 C1092.64953,674.493125 1092.64953,671.782723 1092.64953,669.056471 C1100.27353,669.056471 1107.92923,669.056471 1115.56907,669.056471 C1116.02873,675.713599 1115.04601,682.862086 1110.71888,688.219489 C1104.77502,695.859336 1093.8066,698.110079 1084.86703,695.114372 C1075.19824,691.779535 1068.7113,682.676172 1068.71557,672.448436" id="Path"></path>
            </g>
        </g>
    </svg>
    </a>
  )
}