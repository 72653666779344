import React from "react"
import LinkedInIcon from './LinkedInIcon'
import GithubIcon from './GithubIcon'
import InstagramIcon from './InstagramIcon'
import GoogleIcon from './GoogleIcon'
import classNames from 'classnames'
import * as styles from './socialbar.module.scss'

export default function SocialBar ({ className }) {
  return (
    <ul className={classNames(
      styles.wrapper,
      className
    )}>
      <li>
        <LinkedInIcon
          className={styles.icon}
        />
      </li>
      <li>
        <GithubIcon
          className={styles.icon}
        />
      </li>
      <li>
        <GoogleIcon
          className={styles.icon}
        />
      </li>
      <li>
        <InstagramIcon
          className={styles.icon}
        />
      </li>
    </ul>
  )
}