import React from "react"
import * as styles from './layout.module.scss'
import SocialBar from "../components/social/SocialBar"

export default function Layout({ children }) {
  return (
    <main className={styles.layout}>
      {children}
      <SocialBar className={styles.social} />
    </main>
  )
}