import * as React from "react"
import Layout from '../components/layout'
import * as styles from './index.module.scss'

export default function Home() {
  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>
            <span>hey</span>
            my name<br/> is dina
          </h1>
          <p className={styles.copy}>
            I’m a front-end web developer and designer in Austin. I build, and occasionally design, awesome digital experiences. Hang tight, I'm working on something to make me look good here.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => <title>hey my name is dina</title>
