import React from "react"
import { useState } from "react"

export default function LinkedInIcon ({ className }) {
  const [isHovering, setIsHovering] = useState("#8892B0")

  return (
    <a
      className={className}
      href="https://linkedin.com/in/heymynameisdina"
      target="_blank"
      onMouseEnter={() => setIsHovering("#CCD6F6")}
      onMouseLeave={() => setIsHovering("#8892B0")}
      alt="LinkedIn Link"
      rel="noreferrer"
      aria-label="LinkedIn Link"
    >
      <svg
        width="30px"
        height="30px"
        viewBox="0 0 30 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
      >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Desktop" transform="translate(-1342.000000, -790.000000)" fill={isHovering} fillRule="nonzero">
                <g id="Linkedin-8" transform="translate(1342.000000, 790.000000)">
                    <path d="M0.491287004,9.93281545 L6.70795717,9.93281545 L6.70795717,29.9244174 L0.491287004,29.9244174 L0.491287004,9.93281545 Z M7.20554273,3.60277136 C7.20554273,5.59252704 5.59252704,7.20554273 3.60277136,7.20554273 C1.61301568,7.20554273 0,5.59252704 0,3.60277136 C0,1.61301568 1.61301568,0 3.60277136,0 C5.59252704,0 7.20554273,1.61301568 7.20554273,3.60277136 Z" id="Shape"></path>
                    <path d="M10.6067604,9.93281545 L16.5588915,9.93281545 L16.5588915,12.6663867 L16.6722654,12.6663867 C17.8870886,10.590559 20.1453336,9.35130768 22.5488138,9.44152845 C28.8473651,9.44152845 30,13.5796767 30,18.9586395 L30,29.9244174 L23.7896284,29.9244174 L23.7896284,20.2057527 C23.7896284,17.8878858 23.7518371,14.9023725 20.5647701,14.9023725 C17.3777031,14.9023725 16.8360277,17.421793 16.8360277,20.0356918 L16.8360277,29.9244174 L10.6319547,29.9244174 L10.6067604,9.93281545 Z" id="Path"></path>
                </g>
            </g>
        </g>
      </svg>
    </a>
  )
}