import React from "react"
import { useState } from "react"

export default function GithubIcon ({className }) {
  const [isHovering, setIsHovering] = useState("#8892B0")

  return (
    <a
      className={className}
      href="https://github.com/heymynameisdina"
      target="_blank"
      onMouseEnter={() => setIsHovering("#CCD6F6")}
      onMouseLeave={() => setIsHovering("#8892B0")}
      alt="Github Link"
      rel="noreferrer"
      aria-label="Github Link"
    >
      <svg
        width="30px"
        height="30px"
        viewBox="0 0 30 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
      >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Desktop" transform="translate(-1342.000000, -848.000000)" fill={isHovering}>
              <g id="Github-8" transform="translate(1342.000000, 848.000000)">
                  <path d="M14.9559721,0 C7.61668718,0.00683852353 1.36490386,5.33389866 0.19330439,12.5790698 C-0.978295082,19.8242409 3.2759108,26.8501997 10.2389865,29.1698391 C10.9874149,29.308204 11.2578554,28.8427948 11.2578554,28.4528573 C11.2578554,28.0629198 11.2578554,26.9119753 11.2578554,25.6666911 C7.09432941,26.566063 6.22640405,23.8993938 6.22640405,23.8993938 C5.54715812,22.1698324 4.56602511,21.7044232 4.56602511,21.7044232 C3.20753325,20.779894 4.66665414,20.7987619 4.66665414,20.7987619 C5.62871514,20.9304164 6.47599625,21.4991394 6.96225381,22.3396439 C8.29558841,24.6289543 10.4654018,23.9685763 11.3207485,23.5975067 C11.3843975,22.8377581 11.7218932,22.1270056 12.270435,21.5975048 C8.94338777,21.220146 5.44652909,19.9308366 5.44652909,14.1949821 C5.42563407,12.7080057 5.97993393,11.2704313 6.99370038,10.1823996 C6.53224662,8.89192816 6.58402981,7.4735192 7.1383546,6.22013171 C7.1383546,6.22013171 8.39621744,5.8176156 11.2515661,7.75472437 C13.7054919,7.08383341 16.2945027,7.08383341 18.7484286,7.75472437 C21.6100665,5.8176156 22.86164,6.22013171 22.86164,6.22013171 C23.4159648,7.4735192 23.467748,8.89192816 23.0062942,10.1823996 C24.0183348,11.2710599 24.5704283,12.7087564 24.5471762,14.1949821 C24.5471762,19.9497045 21.0503175,21.2138567 17.716981,21.5849262 C18.4372492,22.3210811 18.8061159,23.331455 18.7295606,24.3585138 C18.7295606,26.3585157 18.7295606,27.9685801 18.7295606,28.4654359 C18.7295606,28.8616627 19,29.327072 19.7610081,29.1824177 C26.7285964,26.8612713 30.983121,19.828114 29.8046701,12.5792341 C28.6262191,5.33035413 22.3629089,0.00708204622 15.0188653,0.0125786283 L14.9559721,0 Z" id="Path"></path>
                  <path d="M5.65407646,21.4905865 C5.65407646,21.5660583 5.50942224,21.5849257 5.4025039,21.5346117 C5.29558555,21.4842972 5.23269241,21.3836682 5.26413898,21.3081964 C5.29558555,21.2327246 5.41508252,21.2138567 5.52200086,21.2641712 C5.6289192,21.3144857 5.69181235,21.4151147 5.65407646,21.4905865 Z" id="Path" fillRule="nonzero"></path>
                  <path d="M6.26413994,22.1635431 C6.19495748,22.2327256 6.05030325,22.201279 5.95596354,22.0943607 C5.86462249,22.0180179 5.84562691,21.8850488 5.91193834,21.7861843 C5.98741011,21.7170018 6.11948571,21.7484484 6.22011474,21.8553667 C6.32074376,21.9622851 6.33961171,22.10065 6.26413994,22.1635431 Z" id="Path" fillRule="nonzero"></path>
                  <path d="M6.85533547,23.0314685 C6.76099575,23.0943616 6.61005221,23.0314685 6.52200182,22.8993929 C6.43395142,22.7673173 6.4276621,22.6037951 6.52200182,22.540902 C6.61634153,22.4780088 6.76099575,22.540902 6.85533547,22.6666883 C6.94967518,22.7924745 6.94967518,22.962286 6.85533547,23.0314685 Z" id="Path" fillRule="nonzero"></path>
                  <path d="M7.66036768,23.8616579 C7.5786066,23.9497083 7.4025058,23.9245511 7.27671952,23.8050541 C7.15093323,23.6855571 7.10690803,23.5094563 7.19495843,23.421406 C7.28300883,23.3333556 7.45282031,23.3585128 7.5786066,23.4780098 C7.70439288,23.5975067 7.74841808,23.7736075 7.66036768,23.8616579 Z" id="Path" fillRule="nonzero"></path>
                  <path d="M8.7798656,24.3459351 C8.74212972,24.4591428 8.57231823,24.5157466 8.40250675,24.4654321 C8.23269527,24.4151176 8.12577693,24.2767527 8.1572235,24.1572557 C8.18867007,24.0377587 8.36477087,23.9874442 8.53458235,24.0377587 C8.70439383,24.0880733 8.81131217,24.2264382 8.7798656,24.3459351 Z" id="Path" fillRule="nonzero"></path>
                  <path d="M9.99999255,24.4339855 C9.99999255,24.5597718 9.86162764,24.6604008 9.68552684,24.6604008 C9.50942604,24.6604008 9.3584825,24.5660611 9.3584825,24.4465642 C9.3584825,24.3270672 9.49684742,24.2201488 9.67923753,24.2138595 C9.86162764,24.2075702 9.99999255,24.3144886 9.99999255,24.4339855 Z" id="Path" fillRule="nonzero"></path>
                  <path d="M11.1446477,24.2390168 C11.1446477,24.3585138 11.0377294,24.4843 10.8616286,24.5157466 C10.6855278,24.5471932 10.5282949,24.4780107 10.509427,24.3585138 C10.4905591,24.2390168 10.610056,24.1069412 10.7861568,24.0754946 C10.9622576,24.0440481 11.1194905,24.1195198 11.1446477,24.2390168 Z" id="Path" fillRule="nonzero"></path>
              </g>
            </g>
        </g>
      </svg>
    </a>
  )
}